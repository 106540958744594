"use client";
import { useObservable } from "react-use";
import makeStyles from "@mui/styles/makeStyles";

import { Redirect } from "react-router-dom";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Typography from "@mui/material/Typography";

import { AppConfig } from "config";
import { site$ } from "state/site/query";

const useStyles = makeStyles(() => ({
  card: {
    position: "absolute",
    top: "40%",
    left: "50%",
    zIndex: 3,
    minWidth: 350,
    maxWidth: "100%",
    minHeight: 350,
    padding: 30,
    transform: "translate(-50%, -50%)",
  },

  iconContainer: {
    fontSize: "40px",
    textAlign: "center",
  },

  title: {
    marginBottom: 20,
    textAlign: "center",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const site = useObservable(site$);

  if (site) {
    if (window.location.hostname === AppConfig.signupHost) {
      // Bounce off this page if we're in the
      return <Redirect to="/sites/main" />;
    }
  }

  return (
    <Card className={classes.card}>
      <div className={classes.iconContainer}>
        <SearchOffIcon sx={{ fontSize: 60 }} />
      </div>
      <Typography variant="h5" className={classes.title}>
        404 - Not Found
      </Typography>
      <Typography className={classes.title} gutterBottom>
        The site you are looking for is not available.
      </Typography>
      <Typography variant="subtitle2">Other options:</Typography>
    </Card>
  );
};

export default NotFound;
